<template>
    <h1 style="color: black; opacity: 1;">{{ headerText }}</h1>
    <h3 v-if="editingExisting" style="color: black; opacity: 1;">{{ oldOrganizationVals.organizationName }}</h3>

    <form @submit.prevent="submitForm">
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>Name<span>*</span></label>
                <input class="form-control" style="height: 41px" type="text"
                       v-model="outstateOrganization.organizationName"
                       id="organizationName" placeholder="Organization Name (Required)" @change="validStr($event)"
                       required
                    tabindex="1"
                />
                <transition name="fade">
                    <span v-if="msg.organizationName">Organization Name is {{ msg.organizationName }}</span>
                </transition>
            </div>
            <div class="col-4">
                <label>Address<span>*</span></label>
                <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.address"
                       id="address" placeholder="Address" @change="validStr($event)" required tabindex="6"/>
                <transition name="fade">
                    <span v-if="msg.address">Address is {{ msg.address }}</span>
                </transition>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>Contact Name<span>*</span></label>
                <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.contactName"
                       id="contactName" placeholder="Contact Name (Required)" required
                    tabindex="2"
                />
            </div>
            <div class="col-4">
                <label>City<span>*</span></label>
                <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.city"
                       id="city"
                       placeholder="City" @change="validStr($event)"
                       tabindex="7"
                       required/>
                <transition name="fade">
                    <span v-if="msg.city">City is {{ msg.city }}</span>
                </transition>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>Contact Email<span>*</span></label>
                <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.contactEmail"
                       id="contactEmail" placeholder="Contact Email (Required)" @change="validateEmail($event)"
                       required
                    tabindex="3"
                />
                <transition name="fade">
                    <span v-if="msg.email">{{ msg.email }}</span>
                </transition>
            </div>
            <div class="col-4">
                <label>Postal Code<span>*</span></label>
                <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.postalCode"
                       id="postalCode"
                       placeholder="Postal Code" @change="validStr($event)"
                       tabindex="8"
                       required/>
                <transition name="fade">
                    <span v-if="msg.postalCode">Postal Code is {{ msg.postalCode }}</span>
                </transition>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>Phone Number<span>*</span></label>
                <div v-if="!showMazInput">
                    <input class="form-control" style="height: 41px" v-model="outstateOrganization.phoneNumber"
                           id="phoneNumber" placeholder="Phone Number (Required)" @change="validStr($event)" required
                    tabindex="4"/>
                </div>
                <div v-else>
                    <maz-phone-number-input
                            :default-country-code="outstateOrganization.countryCode"
                            v-model="outstateOrganization.phoneNumber"
                            no-country-selector
                            size="sm"
                            color="primary"
                            tabindex="4"
                    />
                </div>
                <transition name="fade">
                    <span v-if="msg.phoneNumber">Phone Number is {{ msg.phoneNumber }}</span>
                </transition>
            </div>
            <div class="col-4">
                <CountrySelectComposition
                        @selectedCountry="changeCountry($event)"
                        @selectedState="changeState($event)"
                        :defaultState="originalState"
                        :country-disabled="editingExisting"
                        :state-disabled="editingExisting"
                        :default-country="originalCountry"
                        :show-state="false"
                        :show-esu="false"
                        :show-district="false"
                        :show-school="false"
                        :tab-idx="9"
                />
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>Fax Number</label>
                <div v-if="!showMazInput">
                    <input class="form-control" style="height: 41px" v-model="outstateOrganization.faxNumber"
                           id="faxNumber" placeholder="Fax Number"
                    tabindex="5"/>
                </div>
                <div v-else>
                    <maz-phone-number-input
                            :default-country-code="outstateOrganization.countryCode"
                            v-model="outstateOrganization.phoneNumber"
                            no-country-selector
                            size="sm"
                            color="primary"
                            tabindex="5"
                    />
                </div>
            </div>
            <div class="col-4">
                <div v-if="outstateOrganization.countryCode === 'US'">
                    <state-select-composition
                            @selectedState="changeState($event)"
                            :defaultState="originalState"
                            :state-disabled="editingExisting"
                            :default-country="originalCountry"
                            :show-esu="false"
                            :show-district="false"
                            :show-school="false"
                            :tab-idx="10"
                    />
                </div>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col">
                <button class="button btn-primary" type="submit" :disabled="!canSubmit">
                    {{ submitText }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import {computed, nextTick, onBeforeMount, ref, watch} from "vue";
import {API_URL} from "../../../../../Constants";
import axios from "axios";
import CountrySelectComposition from "@/components/LocationSelect/CountrySelectComposition";
import {createPhoneInputMask, numericStringMask} from "@/InputMaskFunctions";
import {EMAIL_REGEX} from "@/ValidConstants";
import {isEqual} from "lodash/lang";
import {useStore} from "vuex";
import Swal from "sweetalert2";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import StateSelectComposition from "@/components/LocationSelect/StateSelectComposition.vue";

export default {
	name: "OutstateOrganizationForm",
	components: {StateSelectComposition, CountrySelectComposition, MazPhoneNumberInput},
	props: {
		idOrganization: Number,
		headerText: String,
		submitText: String,
		editingExisting: Boolean
	},
	setup(props, context) {
		const store = useStore();
		const authToken = computed(() => store.getters['auth/authenticated']);
		const authUser = computed(() => store.getters['auth/user']);
		const updatingCountry = ref(false);
		const phoneInputModel = ref();
		let outstateOrganization = ref({});
		let oldOrganizationVals = ref({});

		let originalState = ref("0");
		let originalCountry = ref("US");
		let isLoaded = ref(false);
		let msg = ref([]);

		let isValidEmail = computed(() => {
			return EMAIL_REGEX.test(outstateOrganization.value.contactEmail);
		})


		onBeforeMount(() => {
			if (props.idOrganization !== undefined && props.idOrganization !== null) {
				getOutstateOrg();
			}

			if (!props.editingExisting) {
				if (authUser.value !== null) {
					originalCountry.value = authUser.value.country.countryCode;
				}
				outstateOrganization.value.countryCode = originalCountry.value;
			}

			isLoaded.value = true;
		})

		const showStateDisplay = computed(() => {
			return outstateOrganization.value.countryCode === 'US';
		})

		const showMazInput = computed(() => {
			return !updatingCountry.value && !showStateDisplay.value;
		})

		watch(() => outstateOrganization.value.phoneNumber, () => {

			if (showStateDisplay.value) {
				let mask;
				const numeric = outstateOrganization.value.phoneNumber.replaceAll(/\D/g, '');
				mask = createPhoneInputMask(numeric);
				outstateOrganization.value.phoneNumber = numericStringMask(numeric, mask);
			}
		})

		watch(() => outstateOrganization.value.faxNumber, () => {
			if (showStateDisplay.value) {
				let mask;
				const numeric = outstateOrganization.value.faxNumber.replaceAll(/\D/g, '');
				mask = createPhoneInputMask(numeric);
				outstateOrganization.value.faxNumber = numericStringMask(numeric, mask);
			}
		})

		watch(() => outstateOrganization.value.countryCode, () => {
			originalCountry.value = outstateOrganization.value.countryCode;
		})

		const getOutstateOrg = () => {
			let get_uri = API_URL + "/outNeOrganization/displayById?_idOrganization=" + parseInt(props.idOrganization);
			axios.get(get_uri)
				.then((result) => {
					outstateOrganization.value = result.data;
					outstateOrganization.value.phoneNumber = (result.data.phoneNumber !== null && result.data.phoneNumber !== undefined) ? result.data.phoneNumber : "";
					// outstateOrganization.value.faxNumber = (result.data.faxNumber !== null && result.data.faxNumber !== undefined) ? result.data.faxNumber : "";
					// outstateOrganization.value.contactEmail = (result.data.contactEmail !== null && result.data.contactEmail !== undefined) ? result.data.contactEmail : "";
					// outstateOrganization.value.address = (result.data.address !== null && result.data.address !== undefined) ? result.data.address : "";
					// outstateOrganization.value.city = (result.data.city !== null && result.data.city !== undefined) ? result.data.city : "";
					// outstateOrganization.value.postalCode = (result.data.postalCode !== null && result.data.postalCode !== undefined) ? result.data.postalCode : "";

					originalState.value = result.data.stateCode;
					originalCountry.value = result.data.countryCode;
					changeCountry(outstateOrganization.value.countryCode);
					oldOrganizationVals.value = {...outstateOrganization.value};
				})
		}

		function validateEmail(event) {
			if (outstateOrganization.value.contactEmail.length === 0) {
				msg.value['email'] = 'Email is required.'
			} else if (!isValidEmail.value) {
				msg.value['email'] = 'Invalid Email Address Format.';
			} else {
				delete msg.value['email'];
			}
		}

		function validStr(event) {
			if (outstateOrganization.value[event.target.id].length === 0) {
				msg.value[event.target.id] = 'required.';
			} else {
				delete msg.value[event.target.id];
			}
		}

		async function changeCountry(event) {
			// outstateOrganization.value.countryCode = event;
			// context.emit("countryCode", outstateOrganization.value.countryCode);
			updatingCountry.value = true;
			await nextTick().then(() => {
				outstateOrganization.value.countryCode = event;
				phoneInputModel.value.countryCode = event;
			}).finally(() => {
				updatingCountry.value = false;
			})

		}

		function changeState(event) {
			outstateOrganization.value.stateCode = event;
			context.emit("idState", outstateOrganization.value.stateCode);
		}


		const canSubmit = computed(() => {
			let valid_form;
			let valid_name = (outstateOrganization.value.organizationName !== "" &&
				outstateOrganization.value.organizationName !== undefined && outstateOrganization.value.organizationName !== null);
			let valid_address = (outstateOrganization.value.address !== "" &&
				outstateOrganization.value.address !== undefined && outstateOrganization.value.address !== null);
			let valid_city = (outstateOrganization.value.city !== ""
				&& outstateOrganization.value.city !== undefined && outstateOrganization.value.city !== null);
			let valid_email = EMAIL_REGEX.test(outstateOrganization.value.contactEmail);
			let valid_contact = (outstateOrganization.value.contactName !== "" &&
				outstateOrganization.value.contactName !== undefined && outstateOrganization.value.contactName !== null);

			let state_valid;

			state_valid = showStateDisplay.value ? (outstateOrganization.value.stateCode !== "0"
				&& outstateOrganization.value.stateCode !== null && outstateOrganization.value.stateCode !== undefined) : true;
			if (props.editingExisting) {
				return !isEqual(outstateOrganization.value, oldOrganizationVals.value) && valid_email
					&& valid_city && valid_address && valid_name && valid_contact;
			} else {
				valid_form = valid_name && valid_address && valid_city && valid_email && state_valid && valid_contact;
			}
			return valid_form;
		})

		function submitForm() {
			if (props.editingExisting) {
				submitEdit(outstateOrganization.value);
			} else {
				submitCreate(outstateOrganization.value);
			}
		}

		async function submitCreate(postBody) {
			let post_uri = API_URL + "/outNeOrganization/create";
			await axios.post(post_uri, postBody, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				Swal.fire({
					title: "Institution Successfully Created",
					text: "Created " + outstateOrganization.value.organizationName + " successfully.",
					icon: 'success'
				}).then(() => {
					location.reload();
				})
			})
		}

		async function submitEdit(postBody) {
			let post_uri = API_URL + "/outNeOrganization/update";
			await axios.post(post_uri, postBody, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				Swal.fire({
					title: "Institution Successfully Updated",
					text: "Updated " + outstateOrganization.value.organizationName + " successfully.",
					icon: 'success'
				}).then(() => {
					location.reload();
				})
			})
		}

		return {
			outstateOrganization,
			showStateDisplay,
			originalState,
			oldOrganizationVals,
			isLoaded,
			canSubmit,
			originalCountry,
			msg,
			updatingCountry,
			phoneInputModel,
			showMazInput,
			validStr,
			validateEmail,
			changeCountry,
			changeState,
			submitForm
		}
	}
}
</script>

<style scoped>
h4 {
    opacity: 1;
    color: black;
}

span {
    color: red;
}

input {
    --maz-color-info: rgba(59, 130, 246, 0.5);
    height: 41px;
}

</style>